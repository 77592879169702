import React, { useState } from "react";
import { IReportMeta } from "interfaces/service.interface";
import ReportPopup from "components/ReportPopup";

// ReportPopupContext는 ReportPopup을 제어하기 위한 Context를 생성합니다.
// 'show' 메서드를 통해 팝업을 열 수 있으며, 이 Context는 전역적으로 접근할 수 있도록 제공됩니다.
export const ReportPopupContext = React.createContext<{
  show: (suspect: string, meta: IReportMeta) => void;
}>(null!); // 초기값을 null로 설정하며, null을 non-nullable로 처리하기 위해 null!을 사용합니다.

// ReportPopupProvider 컴포넌트는 자식 컴포넌트들에게 ReportPopupContext를 제공하는 Provider 역할을 합니다.
// 이 컴포넌트를 사용하여 ReportPopup 컴포넌트를 전역적으로 관리할 수 있습니다.
const ReportPopupProvider = ({ children }: React.PropsWithChildren<{}>) => {
  // 리포트 팝업이 보일지 여부를 관리하는 state입니다. 초기값은 false로 설정됩니다.
  const [toggle, setToggle] = useState<boolean>(false);

  // 리포트 대상의 이름을 관리하는 state입니다. 선택된 용의자의 이름을 저장하며, 초기값은 undefined입니다.
  const [suspect, setSuspect] = useState<string | undefined>();

  // 리포트 팝업에 전달할 추가적인 메타 데이터를 관리하는 state입니다. 초기값은 null입니다.
  const [meta, setMeta] = useState<IReportMeta | null>(null);

  // 'show' 함수는 팝업을 열기 위해 사용됩니다.
  // 이 함수는 'suspect'와 'meta' 데이터를 받아와 각각의 state를 설정하고, 팝업을 보이도록 toggle 값을 true로 변경합니다.
  const show = (suspect: string, meta: IReportMeta) => {
    setToggle(true);
    setSuspect(suspect);
    setMeta(meta);
  };

  // 'close' 함수는 팝업을 닫기 위해 사용됩니다.
  // 팝업을 숨기기 위해 toggle 값을 false로 변경하고, suspect와 meta state를 초기화합니다.
  const close = () => {
    setToggle(false);
    setSuspect(undefined);
    setMeta(null);
  };

  // Context Provider에 전달할 값입니다. 현재는 팝업을 열기 위한 'show' 함수만 제공됩니다.
  const value = {
    show,
  };

  return (
    // ReportPopupContext.Provider를 통해 Context를 하위 컴포넌트에 전달합니다.
    // children을 포함하여 자식 컴포넌트들은 이 Context에 접근할 수 있게 됩니다.
    <ReportPopupContext.Provider value={value}>
      {children}
      {/* ReportPopup 컴포넌트를 렌더링합니다. */}
      {/* suspect, meta, toggle, onClose props를 전달하여 팝업의 상태와 동작을 관리합니다. */}
      <ReportPopup suspect={suspect} meta={meta} toggle={toggle} onClose={close} />
    </ReportPopupContext.Provider>
  );
};

export default ReportPopupProvider;
