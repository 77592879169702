import request, { Response, TodoResponse } from "./config";
import {
  IChangePasswordData,
  ILogin,
  ILoginData,
  IMe,
  IMeData,
  INotification,
  IRefresh,
  ISignupData,
} from "interfaces/user.interface";
import { IPagination } from "interfaces/service.interface";

// 인증번호 전송
export const postVerifySendCode = (data: { phone: string }) =>
  request.post("/verify/send-code", data);

// 인증번호 확인
export const postVerifyCheckCode = (data: { phone: string; code: string }) =>
  request.post("/verify/check-code", data);

// 로그인
export const postAuthLogin = (data: ILoginData) =>
  request.post<Response<ILogin>>("/auth/login", data);

// 회원가입
export const postAuthRegister = (data: ISignupData) => request.post("/auth/register", data);

// 토큰 갱신
export const postAuthRefresh = (data: IRefresh) =>
  request.post<Response<ILogin>>("/auth/refresh", data);

// 중복 이름 검사
export const postCheckName = (data: { name: string }) =>
  request.post<Response<{ available: false }>>("/auth/check-name", data);

// 중목 이메일 검사
export const postCheckEmail = (data: { email: string; type: "all" | "approval" }) =>
  request.post<Response<{ available: false }>>("/auth/check-email", data);

// 중목 전화번호 검사
export const postCheckPhone = (data: { phone: string; type: "all" | "approval" }) =>
  request.post<Response<{ available: false }>>("/auth/check-phone", data);

// 중목 전화번호 검사
export const postFindEmail = (data: { phone: string }) =>
  request.post<Response<{ email: string; name: string }>>("/auth/find-email", data);

// 비밀번호 재설정
export const updatePassword = (data: IChangePasswordData) =>
  request.post("/auth/change-password", data);

// 사용자 정보
export const getUsersMe = () => request.get<Response<IMe>>("/users/me");

// 사용자 정보 수정
export const patchUsersMe = (data: IMeData) => request.patch("/users/me", data);

// 알림내역 조회
export const getNotifications = (params: IPagination) =>
  request.get<TodoResponse<INotification[]>>("/notifications", { params });

// 알림내역 읽음처림
export const patchNotifications = (notificationId: number) =>
  request.patch(`/notifications/${notificationId}`);

// 알림내역 읽지않은 알림수 조회
export const getNotificationsUnread = () =>
  request.get<Response<{ unreadCount: number }>>("/notifications/unread");
