import env from "./env";

const navs = [
  [
    // {
    //   path: "/dashboard",
    //   basename: "dashboard",
    //   icon: "gauge",
    //   name: "대시보드",
    // },
    // {
    //   path: "/manage/order",
    //   basename: "manage",
    //   icon: "house",
    //   name: "상점관리",
    //   children: [
    //     {
    //       path: "/manage/order",
    //       basename: "order",
    //       name: "주문내역",
    //     },
    //     {
    //       path: "/manage/post",
    //       basename: "post",
    //       name: "게시물관리",
    //     },
    //     {
    //       path: "/manage/follower",
    //       basename: "follower",
    //       name: "팔로워",
    //     },
    //   ],
    // },

    {
      path: "/broadcast",
      basename: "broadcast",
      icon: ["far", "calendar"],
      name: "라이브 예약",
    },
    {
      path: "/point",
      basename: "point",
      icon: ["far", "gem"],
      name: "포인트 관리",
    },
    {
      path: env.LIVE_LIST_SRC,
      basename: "order details",
      icon: "house",
      name: "주문관리",
    },
    {
      path: "/chat",
      basename: "chat",
      icon: ["far", "comment-dots"],
      name: "채팅목록",
    },
  ],
  [
    {
      path: "/profile",
      basename: "profile",
      icon: "cog",
      name: "내 설정",
    },
    {
      path: "/logout",
      basename: "logout",
      icon: "arrow-right-from-bracket",
      name: "로그아웃",
    },
  ],
];

export default navs;
