const env = {
  NODE_ENV: process.env.REACT_APP_NODE_ENV as string,
  BASE_URL: process.env.REACT_APP_BASE_URL as string,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL as string,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL as string,
  VERSION: process.env.REACT_APP_APP_VERTION as string,
  SAVE_KEY: process.env.REACT_APP_SAVE_KEY as string,
  REFRESH_TOKEN: process.env.REACT_APP_REFRESH_TOKEN_KEY as string,
  LIVE_LIST_SRC: process.env.REACT_APP_LIVE_LIST_SRC as string,
  TOSS: {
    REDIRECT_BASE_URL: process.env.REACT_APP_TOSS_REDIRECT_BASE_URL as string,
    CLIENT_KEY: process.env.REACT_APP_TOSS_CLIENT_KEY as string,
    SECRET_KEY: process.env.REACT_APP_TOSS_SECRET_KEY as string,
  },
  TALK_PLUS_APP_ID: process.env.REACT_APP_TALK_PLUS_APP_ID as string,
};

export default env;
