import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClientProvider, QueryClient } from "react-query";
import { CookiesProvider } from "react-cookie";
import ConfirmProvider from "contexts/ConfirmProvider";
import StackSliderProvider from "contexts/StackSlideProvider";
import ReportPopupProvider from "contexts/ReportPopupProvider";
import TalkPlusClientProvider from "contexts/TalkPlusClientContext";
import App from "./App";

import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css";
import "styles/global.css";
import "config/icon";
import AlertProvider from "contexts/AlertProvider";
import Compose from "contexts/Compose";
import PointProvider from "contexts/PointProvider";

dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.tz.setDefault("Asia/Seoul");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <BrowserRouter>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Compose
          components={[
            CookiesProvider,
            ConfirmProvider,
            AlertProvider,
            StackSliderProvider,
            ReportPopupProvider,
            PointProvider,
            TalkPlusClientProvider,
          ]}
        >
          <App />
        </Compose>
      </QueryClientProvider>
    </RecoilRoot>
  </BrowserRouter>
);
