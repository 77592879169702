// TalkPlusClientContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { Client } from "talkplus-sdk"; // TalkPlus 라이브러리 import
import env from "config/env";
import { getTalkPlusLoginToken } from "api/socket.request";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";

// TalkPlus.Client 인스턴스를 생성합니다.
const client = new Client({ appId: env.TALK_PLUS_APP_ID });

// Context 타입 정의
interface TalkPlusClientContextType {
  client: Client;
  isLoginComplete: boolean;
  isPageVisible: boolean; // 페이지 가시성 여부를 추가
}

// Context를 생성합니다. 초기값은 클라이언트 인스턴스입니다.
const TalkPlusClientContext = createContext<TalkPlusClientContextType | undefined>(undefined);

const TalkPlusClientProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isLoginComplete, setIsLoginComplete] = useState<boolean>(false);
  const [isPageVisible, setIsPageVisible] = useState<boolean>(
    document.visibilityState === "visible"
  ); // 초기값 설정
  const user = useRecoilValue(userState);

  // 페이지 가시성 상태를 감지하는 useEffect
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // 리스트 페이지 진입시 첫 1회 로그인 진행
  useEffect(() => {
    const fetchAndLogin = async () => {
      if (!user) return;
      try {
        const response = await getTalkPlusLoginToken();

        // 데이터 구조에 따른 타입 정의
        const { data } = response.data;
        if (!data) {
          throw new Error("서버에서 데이터를 안 줬습니다.");
        }

        const { loginToken, user } = data;
        // 로그인 처리
        if (user && loginToken) {
          await client.loginWithToken({
            userId: user.id,
            loginToken,
          });

          setIsLoginComplete(true);
        }
      } catch (err) {
        // 에러 처리
        console.error("로그인 실패함", err);
      }
    };

    fetchAndLogin();
  }, [user]);

  return (
    <TalkPlusClientContext.Provider value={{ client, isLoginComplete, isPageVisible }}>
      {children}
    </TalkPlusClientContext.Provider>
  );
};

// Context를 사용하는 커스텀 훅을 만듭니다.
export const useTalkPlusClient = (): TalkPlusClientContextType => {
  const context = useContext(TalkPlusClientContext);
  if (!context) {
    throw new Error("provider가 존재해야 함");
  }
  return context;
};

export default TalkPlusClientProvider;
